import {
  request
} from '../utils/request'

const sessionStorage = window.sessionStorage
export function getDictList(dictName) {
  return new Promise((resolve) => {
    const sessionLocalObj = sessionStorage.getItem(dictName)
    if (sessionLocalObj) {
      resolve(JSON.parse(sessionLocalObj))
      return
    }
    request('/visitor/dictData/type/' + dictName, {}, { method: 'get' }).then(res => {
      if (res.code === 200) {
        const dictListStr = JSON.stringify(res)
        sessionStorage.removeItem(dictName)
        sessionStorage.setItem(dictName, dictListStr)
      }
      resolve(res)
    })
  })
}
