import { render, staticRenderFns } from "./ScrollNum.vue?vue&type=template&id=44fdae02&scoped=true&"
import script from "./ScrollNum.vue?vue&type=script&lang=js&"
export * from "./ScrollNum.vue?vue&type=script&lang=js&"
import style0 from "./ScrollNum.vue?vue&type=style&index=0&id=44fdae02&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44fdae02",
  null
  
)

export default component.exports