<template>
  <div class="num-item">
    <div
      v-for="(item, index) in this.realTime"
      :key="index"
      class="real-time-num"
    >
      <div
        class="real-time-num-item"
        :style="{ transform: `translateY(-${item * 4}rem)` }"
      >
        <div>0</div>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
        <div>6</div>
        <div>7</div>
        <div>8</div>
        <div>9</div>
      </div>
    </div>
    <span class="dwFon">{{text}}</span>
  </div>
</template>

<script>
export default {
  name: 'ScrollNum',
  props: {
    num: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: ""
    }
  },
  computed: {
    realTime() {
      let num = String(this.num)
      return num.split('') || ['0', '0', '0']
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped>
.num-item {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  // font-size: 36px;
  // color: #1678ff;
  line-height: 4rem;
  padding: 10px 0;
  .real-time-num {
    display: inline-block;
    height: 4rem;
    overflow: hidden;
    // margin-left: 3px;
    line-height: 4rem;
    text-align: center;

    div {
      height: 4rem;
    }
  }
  .real-time-num-item {
    transition: 800ms ease-in all;
  }
}
.dwFon{
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 5rem;
  display: inline-block;
  height: 4rem;
}
</style>
