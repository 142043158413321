<template>
  <el-dialog title="" class="qrcode-box" :visible.sync="centerDialogVisible" @close="handleClose" @open="handleOpen" >
    <el-image :src="require('../../assets/img/qrcode/qrcode.png')" fit="cover"></el-image>
  </el-dialog>
</template>

<script>
export default {
  props: {
    centerDialogVisible: {
      type: Boolean,
      default: true
    },
    imgUrl: {
      type: String,
      default: '../../assets/img/qrcode/qrcode.png'
    }
  },
  data() {
    return {
    }
  },
  watch: {
    imgUrl(oldV, v) {
      console.log('watch imgUrl')
      console.log(v)
      console.log(oldV)
    }
  },
  methods: {
    handleClose() {
      this.$emit('poupClose')
    },
    handleOpen() {
      this.$emit('poupOpen')
    }
  }
}
</script>
<style scoped lang="scss">
.qrcode-box{
  width: 100%;
  text-align: center;
  .el-dialog{
    width: 50rem;
    height: auto!important;
  }
  .qrcode-img{
    width: 100%;
    text-align: center
  }
}
@media screen and (max-width: 1024px){
  .self-wrap-class{
    .qrcode-box{
      width: 100%;
      text-align: center;
      .el-dialog{
        width: 80%!important;
        height: auto!important;
        margin-top: 20vh!important;
      }
      .qrcode-img{
        width: 100%;
        text-align: center
      }
    }
  }
}
</style>
