var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{class:[_vm.isMobile ? 'self-wrap-class' : ''],style:({ width: '100%' })},[_c('el-header',[_c('Header')],1),('Home'.indexOf(_vm.$route.name) < 0)?_c('div',{staticClass:"HeaderTop"}):_vm._e(),_c('el-main',[_c('keep-alive',{attrs:{"include":[
        'Home',
        'ActivityMore',
        'Enterprise',
        'Information',
        'Mechanism',
        'Needs',
        'Search'
      ]}},[_c('router-view')],1)],1),(
      [
        'VCFrontier',
        'VCFrontierDetail',
        'latestActivity',
        'latestActivityDetail',
        'Map'
      ].indexOf(_vm.$route.name) < 0
    )?_c('el-footer',[_c('Footer')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }