import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    source: {
      token: null,
      cancel: null
    },
    isMobile: false
  },
  getters: {
    xsLeftV: state => {
      return !state.isMobile ? 16 : 24
    },
    xsRightV: state => {
      return !state.isMobile ? 8 : 24
    }
  },
  mutations: {
    initIsMobile(state) {
      var is_mobi = true
      // 判断是手机还是电脑
      is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
      if (is_mobi) {
        state.isMobile = true
        this.$isMobile = true
      } else {
        state.isMobile = false
        this.$isMobile = false
      }
    }
  },
  actions: {},
  modules: {}
});

export default store;
